import styled from 'styled-components';

export const FeedbackModal = styled.div`
  position: relative;
  background: #fff;
  top: ${(props) => (props.isActive ? '30px' : '70px')};
  width: 94%;
  min-height: 230px;
  height: auto;
  margin: 0 3%;
  border-radius: 6px;

  .feedback-header {
    .banner {
      height: 150px;
      width: 100%;
      img {
        border-radius: 2px;
        width: 100%;
        height: 100%;
      }
    }

    .logo {
      position: absolute;
      top: 100px;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      z-index: 5;
      max-width: 100px;

      img {
        width: 100%;
      }
    }

    .title {
      font-family: Arial, Helvetica, sans-serif;
      font-size: 1.5em;
      font-weight: 400;
      letter-spacing: 2.4px;
      color: #43424b;
      position: relative;
      margin: 1em 0 0.4em;
      text-align: center;
      padding-top: 15px;
    }
  }

  .feedback-form {
    text-align: center;
    padding: 25px 30px 50px;
    font-family: sans-serif;
    background-color: ${(props) => props.bgcolor};

    textarea,
    select {
      background-color: #fff;
      border: 0.8px solid #bebebe;
      border-radius: 6px;
      width: 100%;
      font-size: 1em;
      font-weight: 400;
      box-sizing: border-box;
      outline: none;
      margin: 3px 0px;
      color: #43424b;
    }

    textarea {
      width: 100%;
      max-width: 300px;
      border: 2px solid hsl(0, 0%, 85%);
      font-size: 14px;
      padding: 15px 10px;
      outline: none;
      margin: 10px 0px;
      resize: none;

      &:focus {
        border: 2px solid hsl(217, 100%, 75%);
      }

      &::placeholder {
        color: hsl(0, 0%, 75%);
      }
    }

    select {
      padding: 5px 20px;
      outline: none;
      height: 43px;
    }
    .btn {
      border-radius: 0px 0px 6px 6px;
      padding: 15px 0px;
      border: none;
      box-shadow: 0 4px 6px 0 rgba(50, 50, 93, 0.1);
      text-align: center;
      background-color: ${(props) => props.backgroundcolor};
      color: ${(props) => props.color};
      font-size: 0.8em;
      cursor: pointer;
      outline: none;
      width: 100%;
      margin: 15px 0px 0;
      position: absolute;
      bottom: 0px;
      left: 0px;
      transition: all 300ms;

      &:hover {
        background-color: ${(props) => props.backgroundcolor};
      }

      &:disabled {
        cursor: default;
        background-color: hsl(0, 0%, 60%);
      }
    }
    .cancel {
      background-image: -webkit-linear-gradient(355deg, #f8b90a, #e15d05);
      background-image: linear-gradient(95deg, #f8b90a, #e15d05);
    }
  }
`;
