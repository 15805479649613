import React from 'react';

import {
  FacebookIconofManyavar,
  InstagramIconofManyavar,
  PinterestIconofManyavar,
  TollFreeIconofManyavar,
  WebsiteIconofManyavar,
  XTwitterIconofManyavar,
  YoutubeIconofManyavar,
} from '../../assets/images/social';
import { $Flexbox } from '../../css/styles';

export const ManyavarSocial = () => (
  <React.Fragment>
    <$Flexbox className="Socialalign" justify="space-around" padding="16px 0px" alignItems="center">
      <a href="https://www.manyavar.com/" target="_blank" rel="noopener noreferrer">
        <WebsiteIconofManyavar style={{ width: '30px', height: '30px' }} />
      </a>
      <a href="https://www.facebook.com/Manyavar/" target="_blank" rel="noopener noreferrer">
        <FacebookIconofManyavar style={{ width: '33px', height: '30px' }} />
      </a>
      <a href="https://www.instagram.com/manyavar/" target="_blank" rel="noopener noreferrer">
        <InstagramIconofManyavar style={{ width: '33px', height: '30px' }} />
      </a>
      <a href="https://x.com/Manyavar_" target="_blank" rel="noopener noreferrer">
        <XTwitterIconofManyavar style={{ width: '30px', height: '30px' }} />
      </a>
      <a href="https://www.pinterest.com/manyavar_/" target="_blank" rel="noopener noreferrer">
        <PinterestIconofManyavar style={{ width: '33px', height: '30px' }} />
      </a>
      <a href="tel:+1800-120-000-500" target="_blank" rel="noopener noreferrer">
        <TollFreeIconofManyavar style={{ width: '33px', height: '30px' }} />
      </a>
      <a
        href="https://www.youtube.com/@ManyavarCelebrationWear"
        target="_blank"
        rel="noopener noreferrer">
        <YoutubeIconofManyavar style={{ width: '33px', height: '30px' }} />
      </a>
    </$Flexbox>
  </React.Fragment>
);
