import React, { useEffect } from 'react';
const AdsComponent = (props) => {
  const {
    adClient = 'ca-pub-6122583428251315',
    adSlot = '4347907412',
    adFormat = 'auto',
    fullWidthResponsive = 'true',
  } = props;
  useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);

  return (
    <>
      <ins
        className="adsbygoogle"
        style={{ display: 'block' }}
        data-ad-client={adClient}
        data-ad-slot={adSlot}
        data-ad-format={adFormat}
        data-full-width-responsive={fullWidthResponsive || 'true'}
      />
    </>
  );
};

export default AdsComponent;
