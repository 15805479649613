import React from 'react';

import { Campaign } from './Campaign';
import { $CampaignContainer } from './campaigns.styled';

import VaccineBanner from '../../assets/images/covid-vaccine-banner.png';

import { getCampaignMaxHeight, campaignTypes } from './constants';
import { $Block } from '../templates/styles';

export const CampaignType = (props) => {
  const {
    type,
    showVaccineBanner,
    coupons,
    campaignDetails,
    afterSurvey,
    surveyData,
    billUid,
    isButtonPresent,
    isPopupOverBillModalOpen,
    flags,
    handlePOBButtonClick,
    campaignDetailsPOB,
  } = props;
  const { isCinepolisGroup, isMiraj, isManyavar } = flags;

  if (showVaccineBanner && type === campaignTypes.bannerInbill)
    return (
      <$CampaignContainer maxHeight={getCampaignMaxHeight(campaignTypes.bannerInbill)} isBanner>
        <img src={VaccineBanner} />
        <a target="_blank" rel="noopener" href="https://selfregistration.cowin.gov.in/" />
      </$CampaignContainer>
    );

  const campaign = campaignDetails?.filter((camp) => type === camp.type)[0] || null;
  let couponCode = (coupons && coupons[type]) || null;
  let surveyLink = surveyData?.[campaign?.campaignId]?.[0]?.link
    ? `https://${surveyData?.[campaign?.campaignId]?.[0]?.link}`
    : '';

  if (campaign) {
    if (type === campaignTypes.surveyInBill) {
      campaign.componentType = 'sib';
      campaign.buttonImg = campaign?.surveyButton?.button;
      if (Object.keys(afterSurvey || {})?.length > 0) {
        campaign.buttonImg = afterSurvey?.fileLocation;
        couponCode = afterSurvey?.afterSurveyCoupon?.coupon;
        surveyLink = null;
        campaign.couponPosition = afterSurvey?.couponPosition;
        campaign.couponFontColor = afterSurvey?.couponFontColor;
        campaign.couponFontSize = afterSurvey?.couponFontSize;
        campaign.couponFontTypeface = afterSurvey?.couponFontTypeface;
      }
    }

    return (
      <>
        {!isCinepolisGroup ? (
          <Campaign
            key={campaign.campaignId}
            campaign={campaign}
            couponCode={couponCode}
            surveyData={surveyData}
            billUid={billUid}
            type={type}
            isButtonPresent={isButtonPresent}
            surveyLink={surveyLink}
            isPopupOverBillModalOpen={isPopupOverBillModalOpen}
            handlePOBButtonClick={handlePOBButtonClick}
            campaignDetailsPOB={campaignDetailsPOB}
            isManyavar={isManyavar}
          />
        ) : (
          <$Block margin="16px auto" width="90%">
            <Campaign
              key={campaign.campaignId}
              campaign={campaign}
              couponCode={couponCode}
              surveyData={surveyData}
              billUid={billUid}
              type={type}
              isButtonPresent={isButtonPresent}
              surveyLink={surveyLink}
              isPopupOverBillModalOpen={isPopupOverBillModalOpen}
              handlePOBButtonClick={handlePOBButtonClick}
              campaignDetailsPOB={campaignDetailsPOB}
            />
          </$Block>
        )}
      </>
    );
  }
  return null;
};
