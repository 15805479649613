import React, { useEffect, useState, memo } from 'react';
import { centroStoreExitStores } from '../data';
import { clamp, intervalToDuration } from 'date-fns';
import moment from 'moment';
import Axios from 'axios';
import { config } from '../../../config/index.js';
import { useTheme } from 'styled-components';
import CinepolisLogo from '../../../assets/images/cinepolis-logo.svg';
import FunCinemasLogo from '../../../assets/images/fun-cinemas/fun-logo.svg';
import BkTrees from '../../../assets/images/burger-king/trees.png';
import BKLogo from '../../../assets/images/burger-king/bk-new-logo.svg';
import ManyavarHeaderImg from '../../../assets/images/manyavar-bill-header-image.png';

import {
  $StoreHeader,
  $ButtonWithIcon,
  $BSFStoreButton,
  $Div,
  $InViewAnim,
  $SlideAnim,
  $AdDetail,
  $AdDescript,
  $BSFLogo,
  $CarnivalGradiantHeader,
  $CineIndoFnb,
} from '../../templates/styles';
import {
  $Flexbox,
  $Button,
  $DecathStoreExit,
  $DecathlonSecurityCheckIndicator,
  StyledHeader,
} from '../../../css/styles';
import DecathlonGreenCheckImage from '../../../assets/images/decathlon/green-check.png';
import RightArrow from '../../../assets/images/thin-right-arrow.png';

const AdDetail = ({ phone, storeAddress, isNyCinemas }) => {
  return (
    <$AdDetail background={isNyCinemas ? 'transparant' : '#ffffff'}>
      <span>
        <$AdDescript color={isNyCinemas ? true : false}>{storeAddress}</$AdDescript>
        {phone && (
          <$AdDescript>
            <span />
            {phone}
          </$AdDescript>
        )}
      </span>
    </$AdDetail>
  );
};

const getDecathIndiaStoreExit = (startTime, isCentro) => {
  if (!startTime) return {};

  const { hours, minutes, seconds, years, months, days } = intervalToDuration({
    start: new Date(startTime),
    end: new Date(),
  });

  let isTimeElapsed = hours >= 1 || days >= 1 || months >= 1 || years >= 1;

  if (isCentro && !isTimeElapsed) {
    isTimeElapsed = minutes >= 30;
  }

  return {
    hours,
    minutes,
    seconds,
    isTimeElapsed,
  };
};
const _formatCentroInvoiceDate = (dataStr) => {
  if (!dataStr) return null;
  const formatterStr = dataStr?.replace(/\uFFFD/g, ' ');
  const date = moment(formatterStr, ['DD/MM/YY hh:MM:ss a', 'DD-MM-YYYY hh:MM:ss a']);
  return date.isValid() ? date.toDate() : false;
};

const Header = (props) => {
  const {
    storeId,
    flags,
    parsedData,
    createdDate,
    storeExit,
    billUid,
    displayAddress,
    storeAddress,
    brandLogo,
    brandName,
    bestFeedRating,
    bestFeedFinished,
    place,
    other,
  } = props;
  const {
    isMarcaEcomm,
    isNykaaEcom,
    isDecathlonIndiaEcom,
    isCentroBrand,
    isBestSeller,
    showSocial,
    isMiraj,
    isCinepolisGroup,
    isBK,
    isFunCinemas,
    isShree,
    isCinepolisTicket,
    isMovieMaxTicket,
    isRelaxo,
    isCarnival,
    isJalsaCarnival,
    isConnplex,
    isGadchiroli,
    isCityOfPatna,
    isCoussouq,
    isPrasads,
    isRawMango,
    isHdfChargeSlip,
    isRzpPos,
    isNyCinemas,
    isDecathIndPlay,
    isKaya,
    isCinepolisIndonesia,
    isBewakoof,
    isSuperK,
    isManyavar,
  } = flags;

  const theme = useTheme();
  const [decathIndiaStoreExit, setDecathIndiaStoreExit] = useState(
    getDecathIndiaStoreExit(
      parsedData?.transactionalData?.invoiceDate ||
        _formatCentroInvoiceDate(parsedData?.date) ||
        createdDate,
      isCentroBrand,
    ),
  );
  const [isScreenOneActive, setIsScreenOneActive] = useState(true);

  const [initialStoreExit, setInitialStoreExit] = useState(storeExit);
  const setStoreExit = async (type = 'auto') => {
    try {
      const res = await Axios.post(`${config.host}/store-exit/${billUid}`, {
        type,
      });

      if (res?.data?.code) {
        setInitialStoreExit(true);
        setIsScreenOneActive(true);
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    if (decathIndiaStoreExit?.isTimeElapsed && !initialStoreExit) {
      setStoreExit();
    }
  }, [decathIndiaStoreExit?.isTimeElapsed]);

  useEffect(() => {
    if (isDecathlonIndiaEcom || (isCentroBrand && centroStoreExitStores.includes(storeId))) {
      const id = setInterval(
        () =>
          setDecathIndiaStoreExit(
            getDecathIndiaStoreExit(
              parsedData?.transactionalData?.invoiceDate ||
                _formatCentroInvoiceDate(parsedData?.date) ||
                createdDate,
              isCentroBrand,
            ),
          ),
        1000,
      );

      return () => clearInterval(id);
    }
  }, []);
  const logoRedirect = (e) => {
    isCoussouq && window.open('http://www.cossouq.com', '_blank');
  };

  const getRightSideOfScreenOne = () => {
    const getDisplayAddress = () => {
      return isRelaxo && parsedData?.storeData?.displayAddress
        ? parsedData?.storeData?.storeCode + ', ' + parsedData?.storeData?.displayAddress
        : isBewakoof
          ? other?.displayAddress
          : displayAddress || '-';
    };
    const getCommonParts = () => (
      <p style={isNykaaEcom ? { margin: 0 } : isNyCinemas ? { color: 'white' } : {}}>
        {getDisplayAddress()}
      </p>
    );

    if (isMarcaEcomm) return null;

    if (isDecathlonIndiaEcom || (isCentroBrand && centroStoreExitStores.includes(storeId)))
      return (
        <div>
          <$ButtonWithIcon
            right
            type="button"
            {...(decathIndiaStoreExit?.isTimeElapsed || initialStoreExit
              ? {}
              : { onClick: () => setIsScreenOneActive(!isScreenOneActive) })}>
            Security Check
            {decathIndiaStoreExit?.isTimeElapsed || initialStoreExit ? (
              <span>
                <img
                  src={DecathlonGreenCheckImage}
                  alt="Security check green tick"
                  width="13px"
                  height="13px"
                />
              </span>
            ) : (
              <span>
                <img src={RightArrow} alt="" />
              </span>
            )}
          </$ButtonWithIcon>
        </div>
      );

    return (
      <div>
        {getCommonParts()}
        {!isNykaaEcom && !isBestSeller && !isMovieMaxTicket && (
          <$ButtonWithIcon
            right
            type="button"
            onClick={() => setIsScreenOneActive(!isScreenOneActive)}
            style={isNyCinemas ? { color: 'white' } : {}}>
            {isRelaxo ? '' : 'Store Details'}
            <span>
              <img style={isNyCinemas ? { color: 'white' } : {}} src={RightArrow} alt="" />
            </span>
          </$ButtonWithIcon>
        )}
        {isBestSeller && (
          <$BSFStoreButton
            right
            type="button"
            onClick={() => setIsScreenOneActive(!isScreenOneActive)}>
            STORE DETAILS
          </$BSFStoreButton>
        )}
      </div>
    );
  };

  const getScreenTwo = () => {
    if (isMarcaEcomm) return null;

    const getCommonParts = () => {
      if (isDecathlonIndiaEcom || (isCentroBrand && centroStoreExitStores.includes(storeId)))
        return (
          <$Flexbox alignItems="center" padding="0">
            <$DecathStoreExit padding="0">
              {parsedData?.billAmountData?.totalQty ? (
                <$Div>
                  <p>{parsedData?.billAmountData?.totalQty}</p>
                  <p className="store-exit-label">Total Quantity</p>
                </$Div>
              ) : null}
              <$Div>
                <p>{`${('00' + decathIndiaStoreExit?.minutes).slice(-2)}:${(
                  '00' + decathIndiaStoreExit?.seconds
                ).slice(-2)}`}</p>
                <p className="store-exit-label">Time elapsed</p>
              </$Div>
            </$DecathStoreExit>
            <$Button
              onClick={() => setStoreExit('manual')}
              variant="custom"
              style={{ flexBasis: '25%' }}
              bgColor={theme.color.malachite}
              color={theme.color.white}>
              Exit Store
            </$Button>
          </$Flexbox>
        );

      return (
        <>
          <$ButtonWithIcon
            rotate="180deg"
            type="button"
            onClick={() => setIsScreenOneActive(!isScreenOneActive)}>
            <span>
              <img src={RightArrow} alt="" />
            </span>
          </$ButtonWithIcon>
          <AdDetail
            storeAddress={
              isRelaxo && parsedData?.storeData?.storeAddress
                ? parsedData?.storeData?.storeAddress + ', ' + parsedData?.storeData?.pinCode
                : storeAddress
            }
            isNyCinemas={isNyCinemas}
            isBewakoof={isBewakoof}
          />
        </>
      );
    };

    return (
      <$InViewAnim
        inView={isScreenOneActive}
        backgroundColor={isNyCinemas ? '#013B49' : isBewakoof ? '#FDD835' : ''}>
        <$StoreHeader>{getCommonParts()}</$StoreHeader>
      </$InViewAnim>
    );
  };
  if (isGadchiroli || isCityOfPatna || isKaya) {
    return (
      <div style={{ textAlign: 'center' }}>
        <img src={brandLogo} alt={`${brandName} logo`} height="100" className="miraj-logo" />
      </div>
    );
  } else if (isCarnival && !isJalsaCarnival) {
    return (
      <$CarnivalGradiantHeader>
        <img src={brandLogo} alt={`Carnival logo`} width="100%" className="carnival-logo" />
      </$CarnivalGradiantHeader>
    );
  } else if (isCarnival && isJalsaCarnival) {
    return <img src={brandLogo} alt={`Carnival logo`} width="100%" className="carnival-logo" />;
  } else if (isConnplex || isPrasads) {
    return (
      <img
        src={brandLogo}
        alt={`Logo`}
        style={{
          width: isPrasads ? 'clamp(100px,50%,150px)' : 'clamp(150px,50%,200px)',
          margin: 'auto',
          paddingTop: '20px',
          display: 'block',
        }}
      />
      // <img src={brandLogo} alt={`Connplex logo`} style={{ width: "150px", textAlign: "center", margin: "auto", paddingTop: "20px", display: "block" }} />
    );
  } else if (isHdfChargeSlip) {
    return (
      <div style={{ textAlign: 'center', marginTop: '40px' }}>
        <img src={brandLogo} alt={`${brandName} logo`} height="40" className="hdfc-logo" />
        <div>
          {' '}
          <p style={{ textAlign: 'center', fontSize: '18px' }}> We understand your world</p>
        </div>
      </div>
    );
  } else if (isMiraj) {
    return (
      <div className="header">
        <img src={brandLogo} alt={`${brandName} logo`} className="miraj-logo" />
        {/* <img src={MirajHR} alt="Wow" className="miraj-hr" /> */}
      </div>
    );
  } else if (isCinepolisGroup) {
    // return (
    // 	<div className="header">
    // 		<img src={isFunCinemas ? FunCinemasLogo : CinepolisLogo} alt={`${brandName} logo`} />
    // 		{isCinepolisTicket && <h2 className="cinepolis-hr">{place}</h2>}
    // 	</div>
    // )
    return (
      <div className="header">
        <img src={brandLogo} alt={`${brandName} logo`} />
        {isCinepolisTicket && <h2 className="cinepolis-hr">{place}</h2>}
      </div>
    );
  } else if (isBK || isShree) {
    return (
      <>
        <StyledHeader isBK={isBK}>
          <div className="header-bg" />
        </StyledHeader>
        <div className={`bill-app-body ${isBK ? 'bk-header' : ''}`}>
          <img className="logo media-no-print" src={BKLogo} alt="BurgerKing logo" />
          <img
            className="logo bk-trees media-no-print"
            src={BkTrees}
            alt="Save trees, Go digital illustration"
          />
        </div>
      </>
    );
  } else if (isRawMango) {
    return (
      <div style={{ textAlign: 'center', margin: '55px auto' }} className="header">
        <img src={brandLogo} alt={`${brandName} logo`} height="50" className="miraj-logo" />
      </div>
    );
  } else if (isCinepolisIndonesia) {
    return (
      <React.Fragment>
        <div style={{ textAlign: 'center', margin: '55px auto' }} className="header">
          <img src={brandLogo} alt={`${brandName} logo`} height="50" className="miraj-logo" />
        </div>
        <$CineIndoFnb>{displayAddress || place}</$CineIndoFnb>
      </React.Fragment>
    );
  } else if (isSuperK) {
    return (
      <React.Fragment>
        <div style={{ textAlign: 'center', margin: '55px auto' }} className="header">
          <img src={brandLogo} alt={`${brandName} logo`} height="50" className="miraj-logo" />
        </div>
        <$CineIndoFnb>{displayAddress || place}</$CineIndoFnb>
      </React.Fragment>
    );
  } else if (isManyavar) {
    return (
      <React.Fragment>
        <div style={{ textAlign: 'center', backgroundColor: '#4C2008' }} className="header">
          <img
            src={brandLogo}
            alt={`${brandName} logo`}
            height="60px"
            width="72%"
            style={{ margin: '25px' }}
          />
        </div>
        <div>
          <img
            src={ManyavarHeaderImg}
            alt={`${brandName} logo`}
            width="100%"
            style={{ marginBottom: '-25px' }}
          />
        </div>
      </React.Fragment>
    );
  } else if (typeof bestFeedRating == 'number' && !bestFeedFinished) {
    return (
      <$BSFLogo>
        {brandLogo ? (
          <img className="logo" src={brandLogo} alt={`${brandName} logo`} />
        ) : (
          <div className="logo-skeleton" />
        )}
      </$BSFLogo>
    );
  } else if (isDecathIndPlay) {
    return (
      <$SlideAnim
        style={{
          textAlign: 'center',
          margin: '0 auto',
          padding: '25px 0',
          borderRight: '10px solid #3643ba',
          width: 'unset',
        }}
        className="header">
        <img
          src={brandLogo}
          alt={`${brandName} logo`}
          className="logo"
          style={{
            width: '250px',
            display: 'block',
            height: 'auto',
            margin: '0 auto',
          }}
        />
      </$SlideAnim>
    );
  } else {
    return (
      <$SlideAnim isMarcaEcomm={isMarcaEcomm}>
        <$Flexbox padding="0px">
          <$InViewAnim
            inView={isScreenOneActive}
            backgroundColor={
              isNyCinemas ? '#013B49' : isRzpPos ? 'white' : isBewakoof ? '#FDD835' : ''
            }>
            <$StoreHeader isMarcaEcomm={isMarcaEcomm} isNykaaEcom>
              {/* Temporary Style Fix */}
              <img
                style={
                  showSocial
                    ? {
                        margin: '0 12px 0 -12px',
                        minWidth: '135px',
                        objectFit: 'cover',
                      }
                    : {}
                }
                src={brandLogo}
                alt="Brand logo"
                onClick={logoRedirect}
              />
              {getRightSideOfScreenOne()}
            </$StoreHeader>
            {isDecathlonIndiaEcom && !initialStoreExit && <$DecathlonSecurityCheckIndicator />}
          </$InViewAnim>

          {getScreenTwo()}
        </$Flexbox>
      </$SlideAnim>
    );
  }
};

export default memo(Header);
